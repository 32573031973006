import React from "react";
import Seo from "~/components/Seo";
import "~/features/marketing/general-page.css";

function PrivacyPolicyPage() {
  return (
    <div className="container general-page">
      <div>
        <div>
          <h2>RoundEd Learning Privacy Policy</h2>
          <h3>Effective Date: March 24, 2020</h3>
          <p>
            RoundEd Learning Inc. (“<strong>RoundEd Learning</strong>”, “
            <strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>
            ”), a California corporation, is committed to protecting the privacy
            of children and adults who use our products and services. This
            Privacy Policy governs the website located at{" "}
            <a href="/">www.roundedlearning.com</a> (the “<strong>Site</strong>
            ”) and our related products and services, including our downloadable
            apps and courses (collectively, including the Site, the “
            <strong>Products</strong>”). This Privacy Policy (this “
            <strong>Privacy Policy</strong>”) describes the ways in which we
            collect, use, and disclose information about you and the children
            who use RoundEd Learning accounts (each an “<strong>Account</strong>
            ”) and Products.
          </p>
          <p>
            <strong>Please read this Privacy Policy carefully.</strong>&nbsp;By
            using the Products, you agree to the handling of your information in
            accordance with this Privacy Policy and also our{" "}
            <a href="/terms-of-use">
              <span>Terms of Use</span>
            </a>
            .
          </p>
          <h3>SUMMARY</h3>
          <p>
            You should read this entire Privacy Policy, but here is a brief
            summary:
          </p>
          <ul>
            <li>
              <p>
                “<strong>Personally Identifiable Information</strong>”,
                or&nbsp;“<strong>PII</strong>”, is defined in this Privacy
                Policy as information that can be used on its own or with other
                information to identify, contact, or locate a single person or
                to identify an individual in context.
              </p>
            </li>
            <li>
              <p>
                We will never sell or rent the Personally Identifiable
                Information of any User (as defined below) of the Products to a
                third party, except as described in this Privacy Policy or in
                the context of a sale of our company.
              </p>
            </li>
            <li>
              <p>
                During Account registration, the only information we may ask you
                to provide about a child who will be using the Products is the
                child’s first name (or nickname), birth month and year.
              </p>
            </li>
            <li>
              <p>
                Other than in the places and for the purposes explicitly
                disclosed in this Privacy Policy, we do not knowingly collect
                any PII directly from any Child Users (as defined below). For
                more information about where in the Products we may collect PII
                from Child Users, please see&nbsp;
                <a href="#anchor2B">
                  <span>Section 2(B) (Information We Collect from Users)</span>
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                We do not ask for certain sensitive PII such as social security
                numbers, driver’s license numbers, or biometric data. However,
                paying Adult Users (as defined below) are asked for payment
                information when they subscribe. Please see&nbsp;
                <a href="#anchor2">
                  <span>Section 2 (Content You Submit)</span>
                </a>
                &nbsp;for more information about how we safeguard payment
                information.
              </p>
            </li>
            <li>
              <p>
                We use commercially reasonable physical, technical, and
                administrative security measures designed to safeguard all
                information collected by the Products.
              </p>
            </li>
          </ul>
          <p>
            Adult Users may contact us as described in&nbsp;
            <a href="#anchor11">
              <span>Section 11 (Contact Us)</span>
            </a>
            &nbsp;to request that we provide for their review, or delete from
            our records, any PII they have provided about them or the Child
            Users associated with their Account, or to cease collecting PII from
            them or such Child Users. For information about when we
            automatically delete PII due to Account cancellation or inactivity,
            please see&nbsp;
            <a href="#anchor8">
              <span>Section 8 (Location of Information Processing)</span>
            </a>
            .
          </p>
          <h3>
            <span>
              <strong>1. Account Users</strong>
            </span>
          </h3>
          <p>
            Users of the Products include&nbsp;“<strong>Child Users</strong>
            ”&nbsp;(any child under 18 who uses the Products) and&nbsp;“
            <strong>Adult Users</strong>”&nbsp;(including parents, legal
            guardians, or teachers of Child Users), collectively referred to
            as&nbsp;“<strong>Users</strong>”.
          </p>
        </div>
      </div>
      <div>
        <div>
          <div>
            <h3>
              <strong>2. CONTENT YOU SUBMIT</strong>
            </h3>
            <h3>
              <strong>
                A.&nbsp;&nbsp;&nbsp; Information Users Provide to Us
              </strong>
            </h3>
            <p>
              We and our third-party service providers collect information that
              Users provide when using the Products, including when Adult Users
              create an account, sign up for Products, or contact us with a
              question, comment, or request. However, we will only use or
              disclose this information in accordance with this Privacy Policy
              and applicable law. Please see&nbsp;
              <a href="#anchor4">
                <span>
                  Section 4 (When We Disclose Information to Third Parties)
                </span>
              </a>
              &nbsp;for more information and how we limit disclosure of Adult
              User and Child User information.
            </p>
            <p>
              An Adult User must register for an Account on behalf of each Child
              User. Each Adult User is asked to provide certain information
              about himself or herself when registering for an account,
              including email address. After creating an account, an Adult User
              can add Users to the Adult User’s Account. The Adult User will be
              asked to enter just the first name or a nickname for the Child
              User (which will be used to create a login name for the Child
              User), a password, the birth month and year, and gender. The login
              name and password will be used to authenticate individual Child
              Users. If a Child User forgets his or her login name or password,
              the Adult User will be able to recover the information by logging
              into the Adult User’s Account. Payment information will also be
              collected from Adult Users who purchase any Products through the
              Site. Each payment is a one-time payment for a course and not a
              recurring payment. We do not store any credit card information or
              other information provided to authorize the payment.
            </p>
            <p>
              If you submit any information relating to other people in
              connection with the Products, you represent that you have the
              authority to do so and permit us to use the information in
              accordance with this Privacy Policy.
            </p>
            <p>
              We encourage you to use the Products with your Child Users and to
              view and monitor the use of the Products by them.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>
              <strong>
                B.&nbsp;&nbsp;&nbsp;&nbsp; Information We Collect from Users
              </strong>
            </h3>
            <p>
              i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>From the Site:</strong>
            </p>
            <p>
              We and select third parties that we partner with to provide
              analytics and other operational services automatically collect
              information when users visit our Site. Information that may be
              collected when you visit our Site include: (1) IP addresses; (2)
              domain servers; (3) types of computers accessing the Site; (4)
              types of web browsers used to access the Site; (5) referring
              Uniform Resource Locator (URL) that sent you to the Site; (6) URL
              of the site you navigate to directly after leaving our Site; and
              (7) other information associated with the interaction of your
              browser and the Site (collectively, “<strong>Traffic Data</strong>
              ”).
            </p>
            <p>
              A variety of technologies are used to obtain Usage Data (as
              defined below) and Traffic Data, including “cookies”. Cookies are
              small data files stored on your computer.&nbsp;They contain
              information such as your username, user preferences, the pages
              you’ve visited and the activities you’ve performed while using
              the&nbsp;Site&nbsp;and Products. Cookies enable us to recognize
              your browser on return visits to our Site and to remember your
              preferences.&nbsp; You may disable or block cookies or set your
              browser to alert you when cookies are being sent to your device;
              however, by disabling cookies, you may not have access to all of
              the features of the&nbsp;Site&nbsp;or Products. To learn more
              about browser cookies, visit&nbsp;
              <a
                href="http://allaboutcookies.org"
                target="_blank"
                rel="noreferrer"
              >
                allaboutcookies.org
              </a>
              .
            </p>
            <p>
              <strong>ii.&nbsp;&nbsp;&nbsp;&nbsp; From the Products:</strong>
            </p>
            <p>
              We and our third-party service providers collect information
              directly from Users of the Products in the form of the actions
              they take and activities they complete when using the Products (“
              <strong>Usage Data</strong>”). For example, we may collect
              information about patterns of usage, which activities a child
              commences and completes, when a child starts and stops an
              activity, which areas of the Products the child frequents, the
              number of questions answered correctly or incorrectly, and the
              child’s choice of customization. We automatically collect and
              store interaction and learning performance data on databases
              locally and in our servers on Amazon Web Services. This
              information is used to update the progress chart within the apps,
              adjust the personalized learning experience accordingly for
              individual children, and populate the information portal available
              for parents and teachers to monitor their children’s usage.
            </p>
            <p>
              <strong>Push Notifications</strong>. Additionally, if you
              downloaded a Product on your mobile device, as part of your use of
              the Product we may ask if you would like to receive push
              notifications, which may include alerts and notifications, badges,
              banners, and sounds on your mobile device. You may choose to stop
              receiving push notifications at any time by changing the settings
              on your mobile device.
            </p>
            <p>
              We and our third-party service providers may use the information
              collected through these technical methods for a number of
              purposes, including delivering content, tracking and enhancing our
              Users’ experience on the Products. For example, when the User
              returns to the Products after logging in, cookies help the
              Products recognize who the User is without having to log back in.
              For more information, see&nbsp;
              <a href="#anchor3">
                <span>Section 3 (How We Use Information)</span>
              </a>
              . The information collected through these technical methods by the
              Products are used only to support the internal operations of the
              Products. We do not allow third-party advertising networks to
              collect information about Users who are logged in to their
              Accounts.
            </p>
            <h3>
              <strong>3. HOW WE USE INFORMATION</strong>
            </h3>
            <p>
              We will never sell or rent the PII of any User of the Products to
              a third party, except as described in this Privacy Policy or in
              the context of a sale of our company. We and our third-party
              service providers may use the information collected from Users as
              described below.
            </p>
            <h3>
              <strong>
                A.&nbsp;&nbsp;&nbsp;&nbsp; Adult User Information&nbsp;
              </strong>
            </h3>
            <p>Information collected from Adult Users may be used:</p>
            <ol>
              <ul>
                <li>
                  <p>
                    To permit you to register and use the Products, including,
                    for example, to send you communications about Child User
                    progress or your Account.
                  </p>
                </li>
                <li>
                  <p>
                    To complete and fulfill your purchase, such as to process
                    your payments, communicate with you regarding your purchase,
                    and provide you with related customer service.
                  </p>
                </li>
                <li>
                  <p>
                    To respond to your inquiries and fulfill your requests,
                    retrieve your password, or provide technical support.
                  </p>
                </li>
                <li>
                  <p>
                    To send you marketing communications for RoundEd Learning
                    products, relevant research, and newsletters that we believe
                    may be of interest to you. While it is never our intention
                    to send any marketing messages to children, if you believe a
                    Child User has received such a marketing communication,
                    please contact us as described in&nbsp;
                    <a href="#anchor11">
                      <span>Section 11 (Contact Us)</span>
                    </a>
                    &nbsp;. You can always choose to stop receiving these
                    marketing messages by simply following the instructions
                    contained in the message. For all Accounts, you may&nbsp;
                    <em>not</em>&nbsp;opt out of receiving administrative
                    messages from us regarding your Account, such as password
                    reset emails and customer support messages.
                  </p>
                </li>
              </ul>
            </ol>
            <h3>
              <strong>
                B.&nbsp;&nbsp;&nbsp;&nbsp; Child User Information&nbsp;
              </strong>
            </h3>
            <p>Information collected from Child Users may be used:</p>
            <ol>
              <ul>
                <li>
                  <p>
                    To measure a Child User’s performance in activities and to
                    adapt a Child User’s learning experience to the Child User’s
                    learning needs.
                  </p>
                </li>
                <li>
                  <p>
                    To analyze, provide progress reports on, or provide an
                    assessment of a Child User’s performance to the Adult User
                    on the Account.
                  </p>
                </li>
              </ul>
            </ol>
            <h3>
              <strong>
                C.&nbsp;&nbsp;&nbsp;&nbsp; Both Adult and Child User
                Information&nbsp;
              </strong>
            </h3>
            <p>
              Information collected from both Adult Users and Child Users may be
              used:
            </p>
            <ol>
              <ul>
                <li>
                  <p>
                    To allow us to assess and improve the Products, its
                    educational content, and other services we provide, for
                    example, to improve our content and user experience; to
                    research, evaluate, and improve the Products’ educational
                    efficacy; and to inform our understanding of the Products’
                    user base.
                  </p>
                </li>
                <li>
                  <p>
                    To customize, adapt, and personalize Users’ viewing and
                    content-consumption experience, for example, by measuring a
                    Child User’s performance in activities and adapting the
                    Child User’s experience to his or her learning needs.
                  </p>
                </li>
                <li>
                  <p>
                    To maintain and analyze the functioning of the Products.
                  </p>
                </li>
                <li>
                  <p>
                    To avoid any violations of this Privacy Policy, our Terms of
                    Use, or any applicable law.
                  </p>
                </li>
                <li>
                  <p>
                    As we believe to be necessary or appropriate under
                    applicable law in order to: (a) comply with legal process;
                    (b) respond to requests from public and government
                    authorities, including public and government authorities
                    outside your country of residence; (c) detect violations of
                    and enforce our Terms of Use; (d) protect our operations or
                    those of any of our affiliates, including the security of
                    the Products; (e) protect our rights, privacy, safety, or
                    property, or that of our affiliates, you, or others; and (f)
                    allow us to pursue available remedies or limit the damages
                    that we may sustain.
                  </p>
                </li>
              </ul>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <h3>
              <strong>4. When We Disclose Information to Third Parties</strong>
            </h3>
            <p>
              Information collected from both Adult Users and Child Users will
              not be disclosed to third parties except as follows:
            </p>
            <ol>
              <ul>
                <li>
                  <p>
                    To third parties who perform certain services for us, such
                    as process credit cards or conduct or evaluate research
                    (such as on the educational impact of the Products),
                    provided that the service providers agree to keep the
                    information confidential and to use it only for purposes
                    that are permitted by this Privacy Policy.
                  </p>
                </li>
                <li>
                  <p>
                    As we believe to be necessary or appropriate as set forth
                    above in Section 3 and in other instances substantially
                    similar to those listed in this Section.
                  </p>
                </li>
                <li>
                  <p>
                    Where legally required and in certain other cases, we may
                    make such disclosures without first providing notice to
                    Users.
                  </p>
                </li>
                <li>
                  <p>
                    Subject to confidentiality agreements, the terms of this
                    Privacy Policy, and applicable law, information may be
                    disclosed to service providers, advisors, potential
                    transactional partners, or other third parties in connection
                    with the consideration, negotiation, or completion of a
                    corporate transaction in which we are acquired by or merged
                    with another company or we sell, liquidate, transfer, or
                    license all or a portion of our assets in bankruptcy or
                    otherwise. This means that if some or all of our assets are
                    acquired or otherwise transferred or licensed, including in
                    bankruptcy, that such acquirer shall be subject to the same
                    commitments stated under this Privacy Policy.
                  </p>
                </li>
              </ul>
            </ol>
            <p>
              We do not disclose the personally identifiable information of any
              Child User to third parties for any marketing or promotional
              purposes.
            </p>
            <p>
              We also may disclose de-identified and/or aggregated User
              information for any other purpose as permitted by applicable law –
              for example, the distribution of de-identified User records to
              outside researchers or the distribution of reports containing
              aggregate User demographic and traffic patterns – provided that no
              individual Adult User or Child User or any specific end-user
              device can be readily identified.
            </p>
            <h3>
              <strong>
                5. How to Access, Change, or Delete Account Information
              </strong>
            </h3>
            <p>
              An Adult User can review or change the information they provided
              when they registered for the Products, including by adding or
              removing Child Users to or from the Account, by logging into the
              Adult User’s Account from the Site and choosing to edit or delete
              the applicable Account(s). In addition, Adult Users may contact us
              at any time as described in&nbsp;
              <a href="#anchor11">
                <span>Section 11 (Contact Us)</span>
              </a>
              &nbsp;to request that we provide for their review, or delete from
              our records, any PII associated with their Accounts. Please keep
              in mind that a request to delete a User’s PII may lead to the loss
              of saved game data and an inability by the User to use the
              Products or certain portions of the Products.
            </p>
            <p>
              When we change or delete any PII at your request, we will make
              good faith efforts to make the changes in our then-active
              databases as soon as reasonably practicable, generally within
              24-48 hours. Changing setting options may not result in immediate
              changes to the settings, which are subject to our operations and
              maintenance schedules. Please note that information may remain in
              backup or archive records, and we may retain certain data relevant
              to preventing fraud or future abuse or for legitimate business
              purposes, such as analysis of aggregated,
              non-personally-identifiable or de-identified data, Account
              recovery, or if required by law. All retained data will continue
              to be subject to the Privacy Policy in effect at that time.
            </p>
            <h3>
              <strong>
                6. Account Cancellation and Reactivation; Data Deletion
              </strong>
            </h3>
            <p>
              At any time, Adult Users may cancel their Accounts by choosing to
              delete such Accounts from their profile page on the Site. For
              Accounts that have been cancelled, if the account remains inactive
              for 24 months, we will delete all PII in our active databases
              associated with the account (“<strong>Account Information</strong>
              ”). The 24-month period is provided so that you have the ability,
              following cancellation of an Account or inactivity, to reactivate
              your Account and potentially recover previous information and
              resume where the Child User left off. However, we provide no
              guarantee, and shall have no liability or obligation to ensure
              that all Account Information and Product-related progress will be
              available or accessible or all learning or personalization
              features will be able to be recovered or resumed. If a User
              requests that PII be deleted prior to the end of the 24-month
              period of inactivity, we will take commercially reasonable steps
              to satisfy that request.
            </p>
            <p>
              Upon Account cancellation, de-identified Adult User and Child User
              information may nonetheless persist internally in our archive
              files or similar databases and may still be used, on a
              de-identified basis, for our internal support, administrative, and
              record-keeping purposes including, but not limited to, allowing us
              to improve the Products and other services we provide through
              research, evaluation, and analytics as permitted by applicable
              law.
            </p>
            <p>
              Please note that following a request to delete information or your
              Account under Sections 5 or 6 of this Privacy Policy, information
              including PII may remain in backup or archive records, and we may
              retain certain data if required by law, relevant to preventing
              fraud or future abuse or for legitimate business purposes, such as
              Account recovery and customer support, and all subject to our
              internal records retention periods. All retained data will
              continue to be subject to the Privacy Policy in effect at that
              time and applicable law.
            </p>
            <h3>
              <strong>7. Links to Other Products</strong>
            </h3>
            <p>
              The Site may include links to external websites or applications
              (for example, Facebook and Twitter) in areas intended for Adult
              Users. These websites and applications are governed by their own
              privacy policies or information collection practices, which may be
              substantially different from ours. We encourage you to review the
              privacy policies and information collection practices of any
              external websites and apps, as those parties’ practices
              would&nbsp;<em>not</em>&nbsp;be subject to this policy.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>
              <strong>8. Location of Information Processing</strong>
            </h3>
            <p>
              The Products are controlled and operated by us from the United
              States, and we provide the Products for use only by persons
              located in the United States. We make no claims that the Products
              are accessible or appropriate outside of the United States. Access
              to the Products may not be legal by certain persons or in certain
              countries. If you access the Products from outside the United
              States, you do so on your own initiative and are responsible for
              compliance with local laws.
            </p>
            <h3>
              <strong>9. Security</strong>
            </h3>
            <p>
              The security of your personal information is important to us, and
              we employ commercially reasonable physical, technical, and
              administrative security measures designed to safeguard the
              information collected by the Products.
            </p>
            <p>
              Adult Users who hold Accounts create passwords for Accounts for
              Adult Users and Child Users during the registration process. You
              can help protect against unauthorized access to such Accounts and
              PII by selecting and protecting the passwords appropriately,
              limiting access to your computers and browsers, and signing out of
              the Accounts at the end of each use. The Account holder’s email
              address and password are used to authenticate logins. A one-time
              password is emailed to the email address when registering for the
              first time or in the event of a forgotten password. Access to PII
              is limited (through username/password credentials and two-factor
              authentication) to those employees of RoundEd Learning or our
              third-party service providers who require it to perform their job
              functions.
            </p>
            <p>
              Please be aware, however, that no information system can be
              guaranteed to be 100% secure, so we cannot guarantee the absolute
              security of your information. Moreover, we are not responsible for
              the security of information you transmit to the Products over
              networks that we do not control, including the internet and
              wireless networks. If you have reason to believe that your
              interaction with us is not secure, please contact us as described
              in <a href="#anchor11">Section 11 (Contact Us)</a>.
            </p>
            <h3>
              <strong>10. Changes to Our Privacy Policy</strong>
            </h3>
            <p>
              If we update this Privacy Policy, we will notify you by posting
              the revised Privacy Policy on the Site or in the Products, and for
              certain revisions that materially expand the ways in which we use
              or share the information previously collected from you through the
              Products, either display an alert next to the Privacy Policy,
              display an alert upon login to the Products, or directly
              communicate with you (e.g., via the email address associated with
              your Account).
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>
              <strong>11. Contact Us</strong>
            </h3>
            <p>
              RoundEd Learning is located in the United States of America and is
              the operator of the Products. Questions regarding this Privacy
              Policy and issues related to your or a Child User’s information
              for which you are responsible should be directed to us via any of
              the following methods:
            </p>
            <p>RoundEd Learning Inc.</p>
            <p>Pleasanton, CA 94566</p>
            <p>Attn: Vidya Raman, CEO</p>
            <p>
              <a href="mailto:info@roundedlearning.com">
                info@roundedlearning.com
              </a>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Head() {
  return <Seo title="Privacy Policy" />;
}

export default PrivacyPolicyPage;
