import React from "react";
import { useStaticQuery, graphql } from "gatsby";

type SeoProps = {
  readonly title?: string;
  readonly meta?: ReadonlyArray<{
    readonly name: string;
    readonly content: string;
  }>;
  readonly description?: string;
};

function Seo({ description, meta, title }: SeoProps) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = description ?? site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <>
      <title>
        {title} | {defaultTitle}
      </title>
      {[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...(meta ?? []),
      ].map((m) => (
        <meta
          key={m.name ?? m.property}
          name={m.name}
          property={"property" in m ? m.property : undefined}
          content={m.content}
        />
      ))}
    </>
  );
}

export default Seo;
